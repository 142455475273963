<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">JADWAL DEPARTEMEN</p>
          <router-link
            v-if="isAdminBagian"
            :to="{ name: 'jadwal-departemen-add' }"
            class="card-header-icon has-text-light"
          >
            <b-icon class="has-text-light" size="is-small" icon="plus" />
          </router-link>
        </header>
        <div class="card-content table-card-content">
          <b-field position="is-right" class="filter-field" grouped group-multiline>
            <generic-filter-select
              class="filter2"
              apiPath="/programstudi/subdepartemen/"
              field="departemen"
              v-model="filterMap.sub_departemen"
            ></generic-filter-select>
            <cari-input class="filter2" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>

          <b-table
            bordered
            striped
            hoverable
            :loading="loading"
            :data="jadwalList"
            :mobile-cards="false"
          >
            <b-table-column field="matakuliah" label="Jadwal" v-slot="props">
              {{ props.row.departemen }}
              <small class="is-pulled-right">{{ props.row.pembuat }}</small>
              <hr />
              <small class="is-pulled-right">
                {{ props.row.created }}
                <template v-if="props.row.pembuat_id == userId">
                  |
                  <a class="is-pulled-right" @click.stop.prevent="confirmDelete(props.row)">
                    <b-icon class="is-primary" size="is-small" icon="trash" />
                  </a>
                </template>
              </small>
              <img
                class="jadwal-img is-hoverable"
                :src="getImage(props.row.url)"
                @click="getImage(props.row.url, true)"
              />
              <br />
              {{ props.row.catatan }}
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clone, has } from "lodash";
import { mapGetters, mapState } from "vuex";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import JadwalDepartemenList from "../models/jadwalDepartemenList.js";
import { webpPath } from "@/apps/core/modules/utils.js";

export default {
  name: "JadwalDepartemenList",
  components: {
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
  },
  computed: {
    ...mapGetters("accounts", ["isAdminBagian"]),
    ...mapState("accounts", ["userId"]),
  },
  data() {
    this.jadwalListMdl = new JadwalDepartemenList();
    this.listMdl = this.jadwalListMdl;
    return this.jadwalListMdl.getObservables();
  },
  methods: {
    getImage(imageUrl, modal) {
      const url = webpPath(imageUrl);
      if (modal)
        return this.$buefy.modal.open(
          `<p class="image"><img src="${url}"></p>`
        );
      return url;
    },
    confirmDelete(jadwal) {
      this.$buefy.dialog.confirm({
        title: "Menghapus Data",
        message: `Anda yakin akan <b>menghapus</b> jadwal ini?`,
        confirmText: `Hapus Jadwal`,
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.listMdl.delete(jadwal.id, () => {
            const total = this.pagination.total - 1;
            if (this.pagination.prevPage > (Math.ceil(total / this.pagination.limit))) this.pagination.prevPage--;
            if (this.pagination.prevPage < 1) this.pagination.prevPage = 1;
            this.pagination.page = this.pagination.prevPage;
            this.listMdl.load();
          });
        },
      });
    },
  },
  mixins: [onlineCheckMixin, paginationMixin],
  activated() {
    if (has(this.$route.params, "sub_departemen")) {
      this.jadwalListMdl.reset();
      this.filterMap.sub_departemen = this.$route.params.sub_departemen;
      this.filterMap.cari = "";
      this.filterPrevMap = clone(this.filterMap);
    }
    this.fetchData();
  },
};
</script>


<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";

.ol-style {
  margin-left: 15px;
  padding: 5px;
}

.jadwal-img {
  object-fit: contain;
  height: 300px;
  width: 300px;
}

.image img {
  object-fit: contain;
  width: 500px;
}

img.is-hoverable:hover {
  cursor: pointer;
}

/* ::v-deep .kel-filter {
  width: 12rem;
} */
</style>
